import { initializeMobileBrandingAdv } from '../mobile-branding-adv';
import { initializeBrandingAdv } from '../branding-adv';

const $mainContent = document.querySelector(`.js-main-content`);
const $topImgContainer = document.querySelector(`.js-adv-branding-top`);

const scrollFixBranding = (setBrandingPosition) => {
  /* lodash debounce work correctly only several times (need to research)
   * make via Intersection observer if possible */

  let isScrolling;
  window.addEventListener(
    `scroll`,
    () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        setBrandingPosition(window.pageYOffset);
      }, 200);
    },
    false,
  );
};

export let isMobileBrandingActive = false;

export const onSlotRendered = (slotName) => {
  if (/mobile_branding/i.test(slotName) && window.BrandingConfig) {
    /* @TODO temporary remove mobile_branding for devices with width less than 400px */
    if (window.innerWidth < 400 && window.BrandingConfig.rightBrandingImg) {
      return;
    }

    initializeMobileBrandingAdv(
      $mainContent,
      document.querySelector(`.js-header`),
      window.BrandingConfig,
    );

    isMobileBrandingActive = true;
    return;
  }
  if (/branding/.test(slotName) && window.BrandingConfig) {
    /* branding advert hard */
    const $mainContentInner = document.querySelector(`.js-main`);
    const $leftImgContainer = document.querySelector(`.js-adv-branding-left`);
    const $rightImgContainer = document.querySelector(`.js-adv-branding-right`);

    initializeBrandingAdv(
      $mainContent,
      $mainContentInner,
      $topImgContainer,
      $leftImgContainer,
      $rightImgContainer,
      window.BrandingConfig,
      scrollFixBranding,
    );
  }
};
