const ELEMENT_NODE_TYPE = 1;
const TEXT_NODE_TYPE = 3;

export function getNestedText(element) {
  return [...element.childNodes]
    .reduce((text, currentNode) => {
      if (currentNode.nodeType !== ELEMENT_NODE_TYPE && currentNode.nodeType !== TEXT_NODE_TYPE) {
        return text;
      }

      if (currentNode.nodeType === TEXT_NODE_TYPE) {
        return `${text}${currentNode.textContent.trim()} `;
      }

      return currentNode.childNodes.length
        ? `${text}${getNestedText(currentNode)} `
        : `${text}${currentNode.textContent.trim()} `;
    }, ``)
    .trim();
}
