export function initializeBrandingAdv(
  $mainContent,
  $mainContentInner,
  $topImgContainer,
  $leftImgContainer,
  $rightImgContainer, {
    brandingLink,
    topBrandingImg,
    topBrandingImgWidth = 800,
    leftBrandingImg,
    rightBrandingImg,
    asideBrandingImgWidth = 100,
    bgColor = '#e3e3e3',
    targetWindow,
    thirdPartyImpressionTracker
  },
  scrollFixBranding
) {
  if (!brandingLink || !topBrandingImg || !leftBrandingImg || !rightBrandingImg || !targetWindow) {
    console.error('Incorrect Branding advert params');
    return;
  }

  const baseMainContentInnerWidth = window.getComputedStyle($mainContentInner).maxWidth;
  const mainContentInnerWidth = (baseMainContentInnerWidth !== 'none') ? baseMainContentInnerWidth : '1400px';

  /* generate main link */
  const $link = document.createElement('a');
  $link.href = brandingLink;
  $link.target = targetWindow;
  $link.className = 'c-adv-branding__link';
  $mainContent.appendChild($link);

  /* generate imgs */
  const $topImg = document.createElement('img');
  $topImg.src = topBrandingImg;
  $topImg.className = 'c-adv-branding__img-top';

  const $leftImg = document.createElement('img');
  $leftImg.src = leftBrandingImg;
  $leftImg.className = 'c-adv-branding__img-aside';

  const $rightImg = document.createElement('img');
  $rightImg.src = rightBrandingImg;
  $rightImg.className = 'c-adv-branding__img-aside';

  /* set container width */
  $topImg.style.width = `${topBrandingImgWidth}px`;
  $mainContentInner.style.maxWidth = `calc(${mainContentInnerWidth} + ${asideBrandingImgWidth * 2}px)`;
  $leftImgContainer.style.minWidth = `${asideBrandingImgWidth}px`;
  $rightImgContainer.style.minWidth = `${asideBrandingImgWidth}px`;

  /* append elements */
  $mainContentInner.appendChild($link);
  $topImgContainer.appendChild($topImg);
  $leftImgContainer.appendChild($leftImg);
  $rightImgContainer.appendChild($rightImg);

  /* set bg color */
  $mainContent.style.backgroundColor = bgColor;

  /* create third party imps */
  if (thirdPartyImpressionTracker) {
    const thirdParty_DFP = document.createElement('img');
    thirdParty_DFP.src = thirdPartyImpressionTracker;
    thirdParty_DFP.width = '1';
    thirdParty_DFP.height = '1';
    window.document.body.appendChild(thirdParty_DFP);
  }

  function setBrandingPosition(scrollPosition) {
    const headerHeight = 80;
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = $rightImgContainer.getBoundingClientRect();
    const offset = elemRect.top - bodyRect.top;
    const maxTopPosition = $rightImgContainer.offsetHeight - $leftImg.offsetHeight;

    const currentContainerTopCord = $rightImgContainer.getBoundingClientRect().top - headerHeight;
    const fixedPositionImg = scrollPosition - offset + headerHeight;

    if (currentContainerTopCord < 0 && fixedPositionImg <= maxTopPosition) {
      $leftImg.style.top = `${fixedPositionImg}px`;
      $rightImg.style.top = `${fixedPositionImg}px`;
    } else if (fixedPositionImg > maxTopPosition) {
      $leftImg.style.top = `${maxTopPosition}px`;
      $rightImg.style.top = `${maxTopPosition}px`;
    } else {
      $leftImg.style.top = '0px';
      $rightImg.style.top = '0px';
    }
  }

  scrollFixBranding(setBrandingPosition);
}
