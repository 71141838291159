class SharedConfig {
  constructor(gptConfig, prebidConfig) {
    this.sharedId = gptConfig.name;
    this.gptConfig = gptConfig;
    this.prebidConfig = prebidConfig;
    this._uniquePostFix = 1;
  }

  getUniqueConfig() {
    const output = {
      gptConfig: { ...this.gptConfig },
      prebidConfig: this.prebidConfig ? { ...this.prebidConfig } : null,
      sharedId: this.sharedId,
    };

    output.gptConfig.uniqueId = `${this.gptConfig.code}-${this._uniquePostFix}`;

    if (this.prebidConfig) {
      output.prebidConfig.code = `${this.prebidConfig.code}-${this._uniquePostFix}`;
    }

    this._uniquePostFix = this._uniquePostFix + 1;

    return output;
  }

  setResponsiveSizes(sizes) {
    const currentSizes = sizes[this.sharedId];

    try {
      const parsedSizes = JSON.parse(`[${this.gptConfig.size}]`);
      this.gptConfig.size = currentSizes
        ? [...parsedSizes.filter((size) => typeof size === `string`), ...currentSizes]
        : parsedSizes;
    } catch (e) {
      throw new TypeError(`Error parsing slot size`);
    }

    if (this.prebidConfig.mediaTypes) {
      this.prebidConfig.mediaTypes.banner.sizes = currentSizes;
    }
  }
}

export default SharedConfig;
