class AdSlot {
  constructor(sharedConfig = {}, container = document) {
    this.sharedConfig = sharedConfig;
    this.config = sharedConfig.gptConfig;
    this._googleSlot = null;
    this.node = container.querySelector(`#${this.config.code}`);
    this.isLazy = this.node && this.node.dataset.lazyAd === `1`;
    this._adapterLoaded = {};

    if (this.node) {
      this.node.id = this.config.uniqueId;
    }
  }

  get size() {
    if (!this.isOutOfPage && !this.config?.size.length) {
      throw new TypeError(`There is no size property at slot ${this.path}`);
    }

    return this.config.size;
  }

  get countAdapterLoaded() {
    return Object.keys(this._adapterLoaded).length;
  }

  addAdapterLoaded(adapter) {
    this._adapterLoaded[adapter] = true;
  }

  clearAdaptersLoaded() {
    this._adapterLoaded = {};
  }

  get id() {
    return this.config.uniqueId;
  }

  get path() {
    return this.config.slot;
  }

  get frequency() {
    return this.config.frequency;
  }

  get isOutOfPage() {
    return !!this.config.outofpage;
  }

  set googleSlot(slot) {
    this._googleSlot = slot;
  }

  get googleSlot() {
    return this._googleSlot;
  }
}

export default AdSlot;
