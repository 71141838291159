class BaseAdapter {
  constructor() {
    if (new.target === BaseAdapter) {
      throw new TypeError(`Cannot construct Abstract instances directly`);
    }

    this.onLoad = () => {};
  }

  init() {
    throw new Error(`You have to implement the init method!`);
  }

  registerSlots() {
    throw new Error(`You have to implement the registerSlots method!`);
  }

  fetch() {
    throw new Error(`You have to implement the fetch method!`);
  }
}

export default BaseAdapter;
