const fromRectangleToRowSizes = [
  [
    [1024, 0],
    [
      [728, 90],
      [970, 90],
      [1000, 90],
    ],
  ],
  [
    [300, 0],
    [
      [320, 50],
      [320, 100],
      [300, 250],
    ],
  ],
];

const intextSizes = [
  [[1024, 0], [[728, 90]]],
  [
    [300, 0],
    [
      [320, 50],
      [320, 100],
      [300, 250],
    ],
  ],
];

const narrowSizes = [
  [
    [1024, 0],
    [
      [728, 90],
      [970, 90],
      [1000, 90],
    ],
  ],
  [
    [300, 0],
    [
      [320, 50],
      [320, 100],
    ],
  ],
];

const rectangleSizes = [
  [[1024, 0], [[300, 250]]],
  [
    [300, 0],
    [
      [320, 50],
      [320, 100],
    ],
  ],
];

const verticalSizes = [
  [
    [1024, 0],
    [
      [300, 250],
      [300, 600],
      [160, 600],
    ],
  ],
  [
    [300, 0],
    [
      [320, 50],
      [320, 100],
    ],
  ],
];

const currentViewportWidth = Math.min(window.screen.width, window.innerWidth);
const currentViewportHeight = Math.min(window.screen.height, window.innerHeight);

export const getResponsiveSizes = (adSlotSizes) =>
  Object.fromEntries(
    Object.entries(adSlotSizes).map(([adSlotName, { sizes }]) => {
      let size = [adSlotName, []];

      for (const [[screenWidth, screenHeight], bannerSizes] of sizes) {
        if (currentViewportWidth >= screenWidth && currentViewportHeight >= screenHeight) {
          size = [adSlotName, bannerSizes];
          break;
        }
      }

      return size;
    }),
  );

export const sizes = {
  big_1: {
    sizes: fromRectangleToRowSizes,
  },
  big_2: {
    sizes: fromRectangleToRowSizes,
  },
  big_5: {
    sizes: fromRectangleToRowSizes,
  },
  sidebar_1: {
    sizes: rectangleSizes,
  },
  sidebar_5: {
    sizes: verticalSizes,
  },
  Sidebar_in_article: {
    sizes: [[[300, 0], [[300, 250]]]],
  },
  Liveupdate_Mobile: {
    sizes: [
      [
        [300, 0],
        [
          [320, 50],
          [320, 100],
        ],
      ],
    ],
  },
  Liveupdate_Desktop: {
    sizes: [
      [
        [1024, 0],
        [
          [728, 90],
          [970, 90],
          [1000, 90],
        ],
      ],
    ],
  },
  Tenancy_1: {
    sizes: rectangleSizes,
  },
  Tenancy_2: {
    sizes: rectangleSizes,
  },
  Tenancy_3: {
    sizes: rectangleSizes,
  },
  Inner_articles: {
    sizes: narrowSizes,
  },
  Inner_articles_2: {
    sizes: narrowSizes,
  },
  Anchor: {
    sizes: [
      [
        [1024, 0],
        [
          [728, 90],
          [970, 90],
          [1000, 90],
        ],
      ],
      [[300, 0], [[320, 50]]],
    ],
  },
  in_article: {
    sizes: intextSizes,
  },
  in_article_2: {
    sizes: intextSizes,
  },
  in_article_3: {
    sizes: intextSizes,
  },
  web_article_intext_4: {
    sizes: intextSizes,
  },
  web_article_intext_5: {
    sizes: intextSizes,
  },
  web_article_intext_6: {
    sizes: intextSizes,
  },
  web_article_intext_7: {
    sizes: intextSizes,
  },
  web_article_intext_8: {
    sizes: intextSizes,
  },
  web_article_intext_9: {
    sizes: intextSizes,
  },
  web_article_intext_10: {
    sizes: intextSizes,
  },
  web_article_infinite_1: {
    sizes: [
      [[1024, 0], [[728, 90]]],
      [
        [300, 0],
        [
          [320, 50],
          [320, 100],
        ],
      ],
    ],
  },
  interscroller: {
    sizes: [
      [
        [300, 0],
        [
          [320, 480],
          [300, 600],
        ],
      ],
    ],
  },
  web_interstitial: {
    sizes: [
      [
        [300, 0],
        [
          [300, 250],
          [320, 480],
          [300, 600],
        ],
      ],
    ],
  },
};

export const biddersGeoRestrictions = {
  sharethrough: [
    `US`,
    `CA`,
    `DE`,
    `GB`,
    `FR`,
    `AU`,
    `GH`,
    `AE`,
    `CH`,
    `SG`,
    `NL`,
    `NO`,
    `IT`,
    `BE`,
    `AT`,
    `NZ`,
    `SE`,
    `IE`,
    `SL`,
    `ES`,
    `DK`,
    `FI`,
    `SA`,
    `PL`,
    `JP`,
    `RO`,
    `MX`,
    `CZ`,
    `BD`,
    `BR`,
    `PT`,
    `MW`,
    `HR`,
    `LU`,
    `GM`,
    `HU`,
    `TW`,
    `RW`,
    `MZ`,
  ],
  minutemedia: [
    `US`,
    `CA`,
    `GB`,
    `DE`,
    `MX`,
    `AU`,
    `BR`,
    `IT`,
    `ES`,
    `FR`,
    `NZ`,
    `BE`,
    `FI`,
    `SE`,
    `NL`,
    `DK`,
    `CH`,
    `AT`,
    `IE`,
    `NO`,
  ],
  rise: [`US`, `CA`, `GB`, `DE`, `AU`, `FR`, `NL`, `CH`, `IT`, `SE`, `IE`, `ES`],
  unruly: [
    `US`,
    `NG`,
    `KE`,
    `PH`,
    `ZA`,
    `GB`,
    `DE`,
    `CA`,
    `FR`,
    `AU`,
    `SG`,
    `IE`,
    `HK`,
    `ZM`,
    `CH`,
    `NO`,
    `DK`,
    `FI`,
    `AT`,
    `BA`,
  ],
};
