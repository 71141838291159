import { isProperGeolocation } from '../services/get-geolocation';
import {monetizationManager} from "../monetization";

export const intentIqTagInit = () => new IntentIqObject({
  partner: 288563991,
  pbjs: window.pbjs,
  callback: () => window.pbjs,
  timeoutInMillis: 400,
  ABTestingConfigurationSource: `percentage`,
  abPercentage: 95,
  manualWinReportEnabled: false,
  vrBrowserBlackList: ['chrome'],
  domainName: window.Site.fullDomain,
});

// implement a code to extract the 'intentiq.com' value from our JSON.
export const extractIiqId = (json) => {
  if (!json) {
    return null;
  }

  const eids = json.eids || [];

  let intentIqUids = null;

  for (const eid of eids) {
    if (eid.source === `intentiq.com`) {
      intentIqUids = eid.uids
    }
  }

  if (intentIqUids) {
    for (const uid of intentIqUids) {
      if (uid.ext.stype === `ppuid`) {
        return uid.id;
      }
    }
  }

  return null; // Return null if no matching "id" value is found
};

export const getIntentIqTestGroup = (intentIqTag) => {
  const testGroup = { intent_iq_group: '' };

  try {
    testGroup.intent_iq_group = intentIqTag.intentIqConfig.abTesting.currentTestGroup || "U";
  } catch (e) {
    testGroup.intent_iq_group = `N`;
  }

  return testGroup;
};

const intentIqGeoLocations = ['US', 'CA', 'JP', 'AU', 'SG', 'KR', 'TH', 'MY', 'NZ', 'MX', 'PH'];

export const isIntentIqEnabled = (currentGeoLocation) =>
  FEATURES.INTENT_IQ && isProperGeolocation(currentGeoLocation, intentIqGeoLocations);

export const initIntentIq = (targeting) => {
  window.IntentScriptOnLoad = () => {
    const intentIqTag = intentIqTagInit();

    const mutatedTargeting = {
      ...targeting,
      ...getIntentIqTestGroup(intentIqTag),
    };

    // Set Intent test group targeting global for infinite posts
    window.Site.targeting = {
      ...window.Site.targeting,
      ...getIntentIqTestGroup(intentIqTag),
    };

    monetizationManager.ppid = extractIiqId(intentIqTag.getIntentIqData());

    window.pbjs.que.push(() => window.pbjs.refreshUserIds());

    monetizationManager.targeting = mutatedTargeting;
  };

  if (window.IntentScriptIsLoad) {
    window.IntentScriptOnLoad();
  }
}
