import { biddersGeoRestrictions } from './config';

export const filterBiddersByGeo = (currentGeo, prebidConfigs) => {
  const filteredConfigs = { ...prebidConfigs };

  if (filteredConfigs.bids) {
    filteredConfigs.bids = filteredConfigs.bids.filter(
      ({ bidder }) =>
        !biddersGeoRestrictions[bidder] ||
        biddersGeoRestrictions[bidder].some((bidderGeo) => bidderGeo === currentGeo),
    );
  }

  return filteredConfigs;
};
