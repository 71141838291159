const MAX_TOP_IMG_WIDTH = 1242;
const MAX_TOP_IMG_HEIGHT = 390;
const MAX_RIGHT_IMG_WIDTH = 256;
const DEFAULT_SIDE_PADDING = 8;

export function initializeMobileBrandingAdv(
  $mainContent,
  $header,
  {
    brandingLink,
    topBrandingImg = '',
    rightBrandingImg = '',
    bgColor = '#e3e3e3',
    targetWindow,
    thirdPartyImpressionTracker,
  },
) {
  /*
    Max size of the top branding image is 1242x390px.
    To calculate current top image height
    we need to multiply current screen width and image ratio 1242/390
  */
  const topImgHeight = (window.innerWidth * MAX_TOP_IMG_HEIGHT) / MAX_TOP_IMG_WIDTH;
  /*
    When the top image has a max width (1242px), right image has width 256px.
    To calculate current right image width
    we need to multiply current screen width and ratio 256/1242
  */
  const rightImgWidth = rightBrandingImg.length
    ? (window.innerWidth * MAX_RIGHT_IMG_WIDTH) / MAX_TOP_IMG_WIDTH
    : DEFAULT_SIDE_PADDING;
  const $body = window.document.body;
  let isClosed = false;
  $body.style.backgroundColor = bgColor;
  $body.style.padding = rightBrandingImg.length
    ? `${topImgHeight}px ${rightImgWidth}px 0 0`
    : `${topImgHeight}px ${rightImgWidth}px 0`;
  $body.classList.add('l-mobile-branding');

  const $fragment = document.createDocumentFragment();

  const $topImg = document.createElement('img');
  $topImg.src = topBrandingImg;
  $topImg.height = topImgHeight;
  $topImg.className = 'l-mobile-branding__top-img';
  $fragment.appendChild($topImg);

  let $rightImg = null;

  if (rightBrandingImg.length) {
    $rightImg = document.createElement('img');
    $rightImg.src = rightBrandingImg;
    $rightImg.width = rightImgWidth;
    $rightImg.className = 'l-mobile-branding__right-img';
    $fragment.appendChild($rightImg);
  }

  const $link = document.createElement('a');
  $link.href = brandingLink;
  $link.target = targetWindow;
  $link.className = 'l-mobile-branding__link';
  $fragment.appendChild($link);

  if (thirdPartyImpressionTracker) {
    const $thirdParty_DFP = document.createElement('img');
    $thirdParty_DFP.src = thirdPartyImpressionTracker;
    $thirdParty_DFP.width = '1';
    $thirdParty_DFP.height = '1';
    $fragment.appendChild($thirdParty_DFP);
  }

  const $closeButton = document.createElement('button');
  $closeButton.className = 'l-mobile-branding__close-button';
  $fragment.appendChild($closeButton);

  $body.prepend($fragment);

  const topImgObserver = new IntersectionObserver((changes) => {
    if (changes[0].isIntersecting) {
      $header.classList.remove('l-header--fixed');
      $mainContent.classList.remove('l-main-content--header-fixed');
      if (!isClosed && $rightImg) {
        $header.style.width = '100%';
        $rightImg.style.position = 'absolute';
        $rightImg.style.top = `${topImgHeight}px`;
      }
    } else {
      $header.classList.add('l-header--fixed');
      $mainContent.classList.add('l-main-content--header-fixed');
      if (!isClosed && $rightImg) {
        $header.style.width = `calc(100% - ${rightImgWidth}px)`;
        $rightImg.style.position = 'fixed';
        $rightImg.style.top = '0';
      }
    }
  });

  $closeButton.addEventListener('click', () => {
    isClosed = true;
    $body.removeChild($link);
    $body.removeChild($topImg);
    $rightImg && $body.removeChild($rightImg);
    $body.removeChild($closeButton);
    $body.style.backgroundColor = 'initial';
    $body.style.padding = '0';
    $header.style.width = '100%';
    $body.classList.remove('l-mobile-branding');
  });

  topImgObserver.observe($topImg);
}
