const POLICY_VIOLATED_WORDS = [
  `bomb`,
  `chernobyl`,
  `crimea`,
  `denazification`,
  `donbas`,
  `explosion`,
  `genocide`,
  `invade`,
  `kremlin`,
  `kyiv`,
  `mariupul`,
  `martial law`,
  `military invasion`,
  `missile strike`,
  `nato`,
  `nazi`,
  `neo nazi`,
  `neo-nazi`,
  `putin`,
  `russia invades ukraine`,
  `russia ukraine`,
  `russian aggression`,
  `russian invasion`,
  `russia-ukraine`,
  `russia-ukraine conflict`,
  `soviet union`,
  `state of emergency`,
  `ukraine invasion`,
  `ukraine russia`,
  `ukraine-russia`,
  `vladimir`,
  `vladimir putin`,
  `volodymyr`,
  `zelenskyy`,
  `dead`,
  `missing`,
  `kill`,
  `pistol`,
  `agony`,
  `arrested`,
  `disease`,
  `death`,
  `kiss`,
  `incident`,
  `robbery`,
  `fire`,
  `fat without`,
  `doping`,
  `military`,
  `suspect`,
  `accident`,
  `die`,
  `nurse`,
  `alcohol`,
  `fuck`,
  `terror`,
  `gay`,
  `robbe`,
  `punishment`,
  `criminal`,
  `kidnap`,
  `smoking`,
  `run over`,
  `fraud`,
  `slaying`,
  `passed away`,
  `abused`,
  `hospitalised`,
  `militaryss`,
  `lawsuit`,
  `blows`,
  `explode`,
  `utorrent`,
  `dope`,
  `buk`,
  `jail breaking`,
  `qced`,
  `adult`,
  `castro`,
  `shot`,
  `shooting`,
  `arrest`,
  `emergency`,
  `attack`,
  `fights`,
  `girls without`,
  `gun`,
  `detained`,
  `war`,
  `cancer`,
  `steal`,
  `blood`,
  `pandemic`,
  `scandal`,
  `outbreak`,
  `kinky`,
  `corrupt`,
  `free download`,
  `armed`,
  `thug`,
  `betting`,
  `naked`,
  `trapped`,
  `health benefits`,
  `injury`,
  `sick`,
  `amp land`,
  `prison`,
  `imprison`,
  `losing a leg`,
  `suffered`,
  `robber`,
  `blast`,
  `sex`,
  `fetish`,
  `bullied`,
  `mike tyson`,
  `nude`,
  `hack phone`,
  `ill`,
  `donald trump`,
  `allegation`,
  `victim`,
  `ho\\b`,
  `joint`,
  `drunk`,
  `crash`,
  `drug`,
  `injured`,
  `nft`,
  `binance`,
  `bitcoin`,
  `crypto`,
  `murder`,
];

const searchWords = POLICY_VIOLATED_WORDS.map((word) => `\\b${word}`).join(`|`);
const regexp = new RegExp(`(${searchWords})(\\w+?)?\\b`, `gi`);

export const getPolicyViolatedWords = (content) => {
  const words = (content.match(regexp) || []).map((word) => word.toLowerCase().trim());

  return [...new Set(words)];
};
